import React from 'react';

const ContactInfo = ({ location, email, phone }) => {
  return (
    <div style={styles.container}>
      <h2>Contact Information</h2>
      <p><strong>Location:</strong> {location}</p>
      <p><strong>Email:</strong> {email}</p>
      <p><strong>Phone:</strong> {phone}</p>
    </div>
  );
};

const styles = {
    container: {
      backgroundColor: '#f9f9f9',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      maxWidth: '400px',
      margin: '0 auto',
    },
  };

export default ContactInfo;