import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Engineering", "Aviation Fueling", "Fuel Terminal, Tank Farm", "Cross country Pipeline", "Industrial Projects"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
          <span className="tagline">Welcome to FTRU Contracting LLC</span>
          <p style={{ color: 'black' }}>Our focus is on Engineering, Procurement, Construction and Commissioning as well as maintenance of Aviation Fueling Industry,   this includes Fuel hydrant refueling network, Transmission pipeline & Storage facilities. Also to provide services across oil and gas sector.</p>

          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                
                <h1>{`Our Area Of Expertise`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Procurement and Construction of Aviation Refueling Hydrant System", "Aviation Refueling Hydrant system maintenance", "Procurement and Construction of Storage Tanks and Vessels", "Procurement and Construction of process plant piping systems and pipelines", "Storage Tanks Terminal repair and maintenance 
", "Supply and erection of welded heavy steel structures Associated pipe work and process plant installation", "EPC handling of Bulk Plants and Processing Plants" ]'><span className="wrap">{text}</span></span></h1>
                 
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
