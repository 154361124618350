import { Col, Row  } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, photoText  }) => {
  const styles = {
    projImgbx: {
      position: 'relative',
      overflow: 'hidden',
    },
    projTxtx: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      width: '100%',
      padding: '10px',
      boxSizing: 'border-box',
    },
    photoText: {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '20px',
      borderRadius: '5px',
      marginTop: '20px',
    },
    photoTextP: {
      marginBottom: '10px',
    },
  };
  
  return (
    <Row>
      <Col size={12} sm={6} md={4}>
        <div className="proj-imgbx" style={styles.projImgbx}>
          <img src={imgUrl} alt={title} width='100%'/>
          <div className="proj-txtx" style={styles.projTxtx}>
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      </Col>
      <Col sm={6} md={8}>
        <div className="photo-text" style={styles.photoText}>
          {photoText.split('\n').map((item, key) => (
            <p key={key} style={styles.photoTextP}>{item}</p>
          ))}
        </div>
      </Col>
    </Row>
  )
}